<template>
    <div>
        <div style="position: relative;width: 100%;height: 100%;">
            <img style="width: 100%;" src="../assets/images/hyOrder.png">
            <div style="position: absolute;top: 18%;left: 5%;width: 92%;color: black;font-size: 14px;">
                地址：<span>{{address}}</span>
            </div>
            <div style="position: absolute;top: 24%;left: 5%;width: 92%;color: black;font-size: 14px;">
                姓名：<span>{{name}}</span>
            </div>
            <div style="position: absolute;top: 30%;left: 5%;width: 92%;color: black;font-size: 14px;">
                联系电话：<span>{{phone}}</span>
            </div>
            <div>
                <van-form>
                    <van-field name="sendType" style="position: absolute;top: 35%;width: 92%;color: black;font-size: 14px; background-color: #FFC103;">
                        <template #input>
                            <van-radio-group v-model="sendType" direction="horizontal">
                                <van-radio name="1">顺丰快递（快递费8元）</van-radio><h1></h1>
                                <van-radio name="0">普通快递（快递费5元）</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                </van-form>
            </div>
            <div style="position: absolute;top: 90%;width: 100%;color: black;">
                <van-submit-bar :price="sendType==='1'?3790:3490" button-text="立即支付" @submit="onSubmit"/>
                <van-overlay :show="isLoading">
                    <div class="wrapper" @click.stop>
                        <van-loading size="24px" vertical>提交信息中...</van-loading>
                    </div>
                </van-overlay>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "Order",
        data() {
            return {
                isLoading: false,
                sendType: '1',//寄送方式
                name: '',
                phone: '',
                address: ''
            }
        },
        created() {
            this.name = this.$route.query.name
            this.phone = this.$route.query.phone
            this.address = this.$route.query.city + this.$route.query.address
        },
        methods: {
            onSubmit() {
                axios.get('https://outapi.zcwipr.com/wxpay/jsapi?code=' + sessionStorage.getItem('code') + '&outName=' + this.name + '&outMobile=' + this.phone + '&outAddr=' + this.address + '&type=' + this.sendType)
                    .then((res) => {
                        console.log(res)
                        let data = {
                            appId:res.data.data.appId,
                            timeStamp:res.data.data.timeStamp,
                            nonceStr:res.data.data.nonceStr,
                            package:res.data.data.package,
                            signType:"MD5",
                            paySign:res.data.data.sign
                        }
                        this.pay(data)
                    })
            },
            pay(data) {
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                    }
                } else {
                    this.onBridgeReady(data);
                }
            },
            onBridgeReady(data) {
                console.log(data)
                WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        "appId": data.appId,     //公众号名称，由商户传入
                        "timeStamp": String(data.timeStamp),         //时间戳，自1970年以来的秒数
                        "nonceStr": data.nonceStr, //随机串
                        "package": data.package,
                        "signType": "MD5",         //微信签名方式:
                        "paySign": data.paySign    //微信签名
                    }, function (res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            alert("支付成功，请等待工作人员邮寄卡片")
                        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                            alert("支付取消")
                        } else {
                            alert("支付失败")
                        }
                    });
            }
        }
    }
</script>

<style scoped>

</style>
